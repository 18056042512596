























































































































































































































































.title_requests {
  color: #555;
  text-align: right;
  margin-bottom: 20px;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.icons_table_wrapper {
  display: flex;
  gap: 8px;
  justify-content: end;
  padding: 5px 0;
  .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    &.edit {
      background-color: #409eff;
      color: #fff;
    }
    &.remove {
      background-color: #fb404b;
      color: #fff;
    }
  }
}
.status_value {
  padding: 5px 17px;
  width: fit-content;
  border-radius: 20px;
  &.pending {
    background-color: #11bfe3;
    color: #ffffff;
  }
  &.approved {
    background-color: #87cb16;
    color: #ffffff;
  }
  &.declined {
    background-color: #f56c6c;
    color: #ffffff;
  }
  &.accept {
    background-color: #67c23a;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    padding: 7px 17px;
  }
}
